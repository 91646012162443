import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import { Typography, Container, Box } from '@material-ui/core';
import { DevelopmentPageQuery } from '../../types/graphql-types';
import CardImage from '../components/image/card-image';
import RichContent from '../components/text/rich-content';

export interface DevelopmentProps {
  data: DevelopmentPageQuery;
}

const DevelopmentPage = ({ data }: DevelopmentProps): JSX.Element => {
  const { contentfulDevelopment } = data;
  const image = contentfulDevelopment?.heroImage?.gatsbyImageData?.images?.fallback?.src;
  return (
    <>
      <SEO title={contentfulDevelopment?.title || ''} />
      <Box mb={5} mt={5}>
        <Container>
          <Typography component="h1" variant="h3" align="left" color="textPrimary" gutterBottom>
            {contentfulDevelopment?.title}
          </Typography>

          <CardImage image={image} title="Image title" />
          <RichContent
            dangerouslySetInnerHTML={{
              __html: contentfulDevelopment?.body?.childMarkdownRemark?.html || '',
            }}
          />
        </Container>
      </Box>
    </>
  );
};

export default DevelopmentPage;

export const pageQuery = graphql`
  query DevelopmentPage {
    site {
      siteMetadata {
        title
      }
    }
    contentfulDevelopment {
      title
      heroImage {
        gatsbyImageData(width: 1232, height: 1080, quality: 90)
      }
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
